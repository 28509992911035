@use '@angular/material' as mat;
@import '../node_modules/@tdoe/design-system/assets/theme/variables';

@include mat.core();

$tdoe-ccte-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$tdoe-ccte-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tdoe-ccte-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tdoe-ccte-theme: mat.m2-define-light-theme((
  color: (
    primary: $tdoe-ccte-primary,
    accent: $tdoe-ccte-accent,
    warn: $tdoe-ccte-warn,
  )
));

@include mat.all-component-themes($tdoe-ccte-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($tdoe-ccte-theme);

@import '../node_modules/@tdoe/design-system/assets/theme/tdoe-theme.scss';
@import '../node_modules/@tdoe/design-system/assets/theme/all.scss';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


mat-tooltip-component {
    .mat-tooltip.tooltip {
        background-color: $c-primary;
    }
}

th.mat-header-cell.cdk-header-cell
{
    vertical-align: text-top;
    padding-top: 8px !important;
}